.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}
.App-dedaluslogo {
  height: 11vmin;
  pointer-events: none;
}

* {
	font-family: Arial, Helvetica, Verdana;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* #185092; #C0D56C; #3D96B4; #267F8E; #BCC7C4; */
.App-header {
  background-color: white; /* #3D96B4; */ /* #8cb5ff;*/  /* #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
   position: absolute; 
 
  min-height: 6vh;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: left;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  bottom: 0;
  border-top: 1px solid #ccc;
  background-color: #cccccc29;
}

.copy-rights_arabic {  
  padding-right: 20px;
  color: #185092;
  justify-content: right;
  align-items: right;
  float: right;
}
.copy-rights_english {
  color: #185092;
  justify-content: left;
  align-items: left;
}
.App-link {
  color: #61dafb;
}

/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
